import "./src/style/index.scss"
import "react-multi-carousel/lib/styles.css"

export const onClientEntry = () => {
  // IntersectionObserver polyfill for gatsby-background-image (Safari, IE)
  if (!(`IntersectionObserver` in window)) {
    // eslint-disable-next-line
    import(`intersection-observer`)
    console.log(`# IntersectionObserver is polyfilled!`)
  }
}
